.footer {
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-link:hover {
    color: #FFDD00; /* Add hover effect */
  }
  
  .footer-text {
    font-size: 12px;
  }
  
.App {
  text-align: center;
  height: 100vh; /* Ensure the App takes up the full height of the screen */
  display: flex; /* Use flexbox to align children */
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
  padding: 0 10px; /* Add some padding to prevent content from touching the edges */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh; /* Take full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container styles */
.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-width: 800px; /* Set a max-width for the container */
  padding: 30px;
  text-align: center;
  box-sizing: border-box; /* Include padding and border in width calculation */
  margin-top: 20px;
}

/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #eee;
  flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
}

.tab-btn {
  padding: 15px 30px;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  min-width: 120px; /* Prevent tabs from shrinking too much */
}

textarea {
  width: 100%; /* Ensure the textarea takes the full width */
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  height: 120px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

textarea:focus {
  border-color: #EC407A;
  outline: none;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

button {
  background-color: #EC407A;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  padding: 12px 20px;
  border: none;
  box-sizing: border-box;
  margin-right: 15px;
}

.tab-btn.active {
  border-bottom: 3px solid #EC407A;
  color: #555;
}

button:hover {
  background-color: #e6779c;
}

button:active {
  background-color: #EC407A;
}

/* Record Audio Buttons */
.record-btn {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.record-btn:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.record-btn:hover {
  background-color: #EC407A;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 20px; /* Reduce padding on smaller screens */
    max-width: 359px;
  }

  .tab-btn {
    font-size: 1rem; /* Slightly smaller tab text */
    padding: 12px;
  }

  textarea {
    font-size: 0.9rem; /* Adjust textarea font size */
  }

  button {
    font-size: 0.9rem; /* Adjust button font size */
    padding: 10px;
  }

  .record-btn {
    font-size: 16px; /* Ensure record button text size is consistent */
  }
}

/* Mobile-first adjustments */
@media (max-width: 480px) {
  .tabs {
    flex-direction: row; /* Stack tabs vertically on small screens */
    margin-bottom: 20px; /* Reduce space between tabs */
  }

  .tab-btn {
    padding: 12px; /* Reduce padding for small screens */
    font-size: 1.1rem; /* Adjust font size for better readability */
    width: 100%; /* Make tab buttons full width */
  }

  .container {
    padding: 15px; /* Reduce container padding on mobile */
    width: 100%; /* Make container full width */
    margin: 0 auto; /* Center it horizontally */
    max-width: 359px;
    align-items: center;
  }

  textarea {
    font-size: 0.85rem; /* Further reduce font size for small devices */
    height: 100px; /* Slightly reduce textarea height */
  }

  button {
    font-size: 0.85rem; /* Reduce button font size on mobile */
    padding: 10px;
  }

  .record-btn {
    font-size: 14px; /* Slightly smaller font size for mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  h1 {
    font-size: 1.8rem; /* Adjust the header font size for small screens */
  }

  .status {
    font-size: 1rem; /* Adjust status text size on mobile */
  }
}

.select2-container {
  width: 100% !important;
}

.select2-selection {
  height: 40px; /* Adjust the height of the dropdown */
}

.select2-selection__rendered {
  line-height: 40px; /* Center the text vertically */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays all other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-content a {
  color: #007bff;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}

.modal-content input[type="checkbox"] {
  margin-right: 10px;
}

.modal-content label {
  font-size: 16px;
}

.modal-content button {
  margin-top: 20px;
}

/* Coffee container */
.coffee-container {
  text-align: center;
  padding: 15px;
  margin-top: 10px;
  border-radius: 8px;
  color: #fff;
}

.coffee-container p {
  margin: 5px 0;
  font-size: 1em;
}

.coffee-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #FFDD00;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}